<template>
    <div class="powered" >
        <img :src="image" alt="梨子助教" />
    </div>
</template>

<script>

export default {
    components: {},
    props: {},
    data() {
        return {
            image: require('@/assets/images/powered.png')
        }
    },
    watch: {},
    computed: {},
    mounted() {},
    methods: {}
}
</script>
<style lang="scss" scoped>
.powered{
    width: 100%;
    height: 22px;
    text-align: center;
    position: relative;
    bottom: 0px;
    padding-top: 10px;
    img {
        height: 100%;
    }
}
</style>